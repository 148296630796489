exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-new-boiler-js": () => import("./../../../src/pages/new-boiler.js" /* webpackChunkName: "component---src-pages-new-boiler-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-guide-template-js": () => import("./../../../src/templates/guide-template.js" /* webpackChunkName: "component---src-templates-guide-template-js" */),
  "component---src-templates-manufacturer-main-template-js": () => import("./../../../src/templates/manufacturerMain-template.js" /* webpackChunkName: "component---src-templates-manufacturer-main-template-js" */),
  "component---src-templates-manufacturer-page-template-js": () => import("./../../../src/templates/manufacturerPage-template.js" /* webpackChunkName: "component---src-templates-manufacturer-page-template-js" */),
  "component---src-templates-policy-template-js": () => import("./../../../src/templates/policy-template.js" /* webpackChunkName: "component---src-templates-policy-template-js" */)
}

